<template>
  <div>
    <h4>
      {{ $t("time.duration.title") }}
      <help>{{ $t("time.duration.info") }}</help>
    </h4>
    <inputSelect
      :key="data"
      :settings="{ value: data, default: data, values: values }"
      :callback="setSave"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.$store.state.business.unsaved.include.weekdays[
        this.$store.state.business.weekday
      ][2],
      values: {
        "5": "5 " + this.$tc("dates.name.minutes", 5),
        "10": "10 " + this.$tc("dates.name.minutes", 10),
        "15": "15 " + this.$tc("dates.name.minutes", 15),
        "20": "20 " + this.$tc("dates.name.minutes", 20),
        "25": "25 " + this.$tc("dates.name.minutes", 25),
        "30": "30 " + this.$tc("dates.name.minutes", 30),
        "35": "35 " + this.$tc("dates.name.minutes", 35),
        "40": "40 " + this.$tc("dates.name.minutes", 40),
        "45": "45 " + this.$tc("dates.name.minutes", 45),
        "50": "50 " + this.$tc("dates.name.minutes", 50),
        "55": "55 " + this.$tc("dates.name.minutes", 55),
        "60": "1 " + this.$tc("dates.name.hours", 1),
        "90": "90 " + this.$tc("dates.name.minutes", 90),
        "120": "2 " + this.$tc("dates.name.hours", 2),
        "180": "3 " + this.$tc("dates.name.hours", 3),
        "240": "4 " + this.$tc("dates.name.hours", 4)
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setSave(ret) {
      this.data = ret;
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      if (
        typeof data.include.weekdays[this.$store.state.business.weekday] !==
          "undefined" &&
        data.include.weekdays[this.$store.state.business.weekday]
      ) {
        data.include.weekdays[
          this.$store.state.business.weekday
        ][2] = this.data;
      }

      data.next_appointments[this.$store.state.business.weekday] = this.data;
      data.exclude.weekdays[this.$store.state.business.weekday] = [];
      if (this.$store.state.tableau.view === "week") {
        Object.keys(data.include.weekdays).forEach(
          function(k) {
            if (
              typeof data.include.weekdays[k][2] !== "undefined" &&
              data.include.weekdays[k][2]
            ) {
              data.include.weekdays[k][2] = this.data;
              data.next_appointments[k] = this.data * 1;
              data.exclude.weekdays[k] = [];
            }
          }.bind(this)
        );
      }

      this.$store.commit("business/prefetch", data);
      this.$store.commit("tableau/setUpdate");
    },
    changeDuration(add) {
      var keys = Object.keys(this.values);
      var index = keys.indexOf(this.data + "");
      index = index + add;

      if (index < 0) {
        index = keys.length - 1;
      }
      if (index === keys.length) {
        index = 0;
      }

      this.data = keys[index];
      this.setSave();
    }
  }
};
</script>
<style lang="sass" scoped></style>
